import {
  AdminBookingPractitionerForm,
  practitionerQueries,
  LoadingSpinner,
  getPractitionerSelectOptions,
  getApolloClient,
  useDebounce
} from 'shared-library';
import React, { useEffect, useMemo, useState } from 'react';
import { getHpdBackendUrl } from 'packages/locations';
import { ApolloProvider, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Column, Row } from '../Layout';
import { AmountToPay } from '../AmountToPay';

const PractitionerRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
`;

export const InvoicePayment = ({ transaction, setTransaction }) => {
  const onSelectPractitioner = (option) => {
    const invoicePayer = option?.value?.split('|')[0];
    if (invoicePayer !== transaction?.invoicePayer) {
      const transactionInvoicePayer = invoicePayer ? invoicePayer.split('|')[0] : '';
      setTransaction({
        ...transaction,
        invoicePayer: transactionInvoicePayer,
        status: 'pending',
      });
    }
  };

  const { loading, error, refetch } = useQuery(practitionerQueries.PRACTITIONER_LIST_SELECT_INFO, {
    skip: true,
  });

  if (loading || error) {
    return <LoadingSpinner size="small" />;
  }

  const getPractitioners = async (input) => {
    if (input && input.trim().length < 3) {
      return [];
    }

    const newPractitioners = await refetch({ search: input });
    return getPractitionerSelectOptions(newPractitioners.data);
  };

  const loadPractitioners = useDebounce(getPractitioners);

  return (
    <>
      <PractitionerRow>
        <AmountToPay transaction={transaction} setTransaction={setTransaction} />
        <Column />
        <Column>
          <AdminBookingPractitionerForm
            label="Invoice Issued To"
            loadPractitioners={loadPractitioners}
            onSelectPractitioner={onSelectPractitioner}
            selectedPractitioner={transaction?.invoicePayer}
            onSubmit={() => {}}
            onCancel={() => {}}
            isMulti={false}
            column={1}
          />
        </Column>
      </PractitionerRow>
    </>
  );
};

const InvoicePaymentWithApollo = ({ transaction, setTransaction }) => {
  const [apiEndpoint] = useState(getHpdBackendUrl());

  const apolloClient = getApolloClient(apiEndpoint);
  return useMemo(
    () => (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            <InvoicePayment transaction={transaction} setTransaction={setTransaction} />
          </ApolloProvider>
        )}
      </>
    ),
    [transaction],
  );
};

export default InvoicePaymentWithApollo;
